import React from "react";
import JoinOurTeam from "../Components/Common/JoinOurTeam";
import Layout from "../Components/Layout/Layout";

const career = () => {
  return (
    <Layout >
      <JoinOurTeam />
    </Layout>
  );
};

export default career;
