import moment from "moment";
import React, { useState } from "react";

const JoinOurTeam = (props) => {
  // console.log(props,"props")
  const initialValues = {
    FirstName: "",
    LastName: "",
    PhoneNumber: "",
    DateOfBirth: "",
    Email: "",
    Address: "",
    CoverLetter: "",
    Work: "",
    Acheviement: "",
    Role: "",
  };
  const [login, setLogin] = useState(initialValues);
  const onFinish = () => {
    let data = {
      FirstName: login.FirstName,
      LastName: login.LastName,
      PhoneNumber: login.PhoneNumber,
      DateOfBirth: login.DateOfBirth,
      Email: login.Email,
      Address: login.Address,
      CoverLetter: login.CoverLetter,
      Work: login.Work,
      Acheviement: login.Acheviement,
      Role: login.Role,
      CreateDTTM: moment().format("YYYY-MM-DD HH:mm:ss"),
    };
    // console.log(data);
    fetch(
      `https://script.google.com/macros/s/AKfycbyRO8aROcC96a3fv9llPI6cR52g-GpSuWZevG021cLn7QdsoWrcpbl4Ryt_WseruD_7Qw/exec?action=addJoinUs`,
      // console.log("tempDta2"),
      {
        // header: {
        //   "Content-type": "application/json;charset=utf-8",
        //   // "Access-Control-Allow-Origin": true,
        //   // "Access-Control-Allow-Method": "POST",
        // },
        method: "POST",
        body: JSON.stringify(data),
        mode: "cors",

        // redirect: "follow",
      }
      // console.log("tempDta3")
    )
      .then((response) => {
        if (response.status === 200) {
          // navigate(`/thank-you?form=${"feedback"}`);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
    setLogin(initialValues);
    // setIsSave(!isSave);
  };
  return (
    <>
      <div className="max-w-6xl mx-auto py-10 px-3.5">
        <h1 className="text-center text-4xl text-secondary-darkpurple-500 font-bold mb-4 uppercase">
          Want To Join Our Team
        </h1>
        <div className="border-t-8 border-secondary-darkblue-500 pb-4 w-40 max-w-full mx-auto"></div>
        {/* <div className="text-center text-2xl text-secondary-darkpurple-500 mb-8">
          {props.carrers_form_short_desc}
        </div>
        <p className="text-lg text-secondary-darkpurple-500 text-center">
          {props.carrers_form_desc}
        </p> */}
      </div>
      <div className="max-w-4xl mx-auto pb-10">
        <div className= "text-secondary-darkpurple-500 flex flex-col lg:flex-row">
          <div className="flex-1">
            <form className="space-y-5 lg:mx-28 mx-6" action="send">
              <div className="flex justify-center text-secondary-darkpurple-500 text-2xl border-b border-gray-400 pb-2">
                Personal Information
              </div>
              <div className="flex justify-between flex-col lg:flex-row">
                <div className="space-y-3">
                  <label htmlFor="first-name" className="text-lg text-secondary-darkpurple-500 pr-3">
                    First Name <span className="text-red-900">*</span>
                  </label>
                  <input
                    type="text"
                    autoComplete="false"
                    className=" form-input text-base p-2.5 bg-transparent border border-secondary-golden-500 sm:w-full  w-full focus:outline-none rounded "
                    value={login.FirstName}
                    onChange={(e) =>
                      setLogin({ ...login, FirstName: e.target.value })
                    }
                    placeholder="First Name"
                    required
                  />
                </div>
                <div className="space-y-3 mt-3 lg:mt-0">
                  <label htmlFor="last-name" className="text-lg text-secondary-darkpurple-500 pr-3">
                    Last Name <span className="text-red-900">*</span>
                  </label>
                  <input
                    type="text"
                    autoComplete="false"
                    className=" form-input text-base p-2.5 bg-transparent border border-secondary-golden-500 sm:w-full  w-full focus:outline-none rounded "
                    value={login.LastName}
                    onChange={(e) =>
                      setLogin({ ...login, LastName: e.target.value })
                    }
                    placeholder="Last Name"
                    required
                  />
                </div>
              </div>
              <div className="flex justify-between flex-col lg:flex-row">
                <div className="space-y-3">
                  <label htmlFor="dob" className="text-lg text-secondary-darkpurple-500 ">
                    Date of Birth <span className="text-red-900">*</span>
                  </label>
                  <input
                    type="text"
                    autoComplete="false"
                    className="form-input text-base p-2.5 bg-transparent border border-secondary-golden-500 sm:w-full  w-full focus:outline-none rounded "
                    value={login.DateOfBirth}
                    onChange={(e) =>
                      setLogin({ ...login, DateOfBirth: e.target.value })
                    }
                    placeholder="DD/MM/YYYY"
                    required
                  />
                </div>
                <div className="space-y-3 mt-3 lg:mt-0">
                  <label htmlFor="contact-no" className="text-lg text-secondary-darkpurple-500 ">
                    Contact No. <span className="text-red-900">*</span>
                  </label>
                  <input
                    type="text"
                    autoComplete="false"
                    className="form-input text-base p-2.5 bg-transparent border border-secondary-golden-500 sm:w-full  w-full focus:outline-none rounded "
                    value={login.PhoneNumber}
                    onChange={(e) =>
                      setLogin({ ...login, PhoneNumber: e.target.value })
                    }
                    placeholder="Contact No."
                    required
                  />
                </div>
              </div>
              <div className="space-y-3">
                <label htmlFor="email-id" className="text-lg text-secondary-darkpurple-500 ">
                  Email Address <span className="text-red-900">*</span>
                </label>
                <input
                  type="email"
                  className="form-input text-base p-2.5 bg-transparent border border-secondary-golden-500 sm:w-full w-full focus:outline-none rounded "
                  value={login.Email}
                  onChange={(e) =>
                    setLogin({ ...login, Email: e.target.value })
                  }
                  placeholder="you@example.com"
                  required
                />
              </div>

              <div className="space-y-3">
                <label htmlFor="address" className="text-lg text-secondary-darkpurple-500 ">
                  Address
                </label>

                <textarea
                  rows={5}
                  className="form-textarea rounded focus:outline-none text-base p-3 bg-transparent border border-secondary-golden-500 sm:w-full w-full "
                  value={login.Address}
                  onChange={(e) =>
                    setLogin({ ...login, Address: e.target.value })
                  }
                  placeholder="Address"
                />
              </div>
              <div className="flex justify-center text-secondary-darkpurple-500 text-2xl border-b border-gray-400 pb-2">
                Details
              </div>
              {/* <div className="flex justify-between"> */}
              <div className="space-y-3">
                <label htmlFor="cover-letter" className="text-lg text-secondary-darkpurple-500 ">
                  Cover Letter
                </label>
                <textarea
                  rows={5}
                  className="form-textarea rounded focus:outline-none text-base p-3 bg-transparent border border-secondary-golden-500 sm:w-full w-full "
                  value={login.CoverLetter}
                  onChange={(e) =>
                    setLogin({ ...login, CoverLetter: e.target.value })
                  }
                  //   placeholder="Address"
                />
              </div>
              <div className="space-y-3">
                <label htmlFor="why-work" className="text-lg text-secondary-darkpurple-500 ">
                  Why do you want to work at AVGSS Hospitality & Restaurant LLP?
                </label>
                <textarea
                  rows={5}
                  className="form-textarea rounded focus:outline-none text-base p-3 bg-transparent border border-secondary-golden-500 sm:w-full w-full "
                  value={login.Work}
                  onChange={(e) => setLogin({ ...login, Work: e.target.value })}
                  //   placeholder="Address"
                />
              </div>
              {/* </div> */}
              <div className="space-y-3">
                <label htmlFor="achivement" className="text-lg text-secondary-darkpurple-500 ">
                  Describe your greatest achievement so far as an employee
                </label>
                <textarea
                  rows={5}
                  className="form-textarea rounded focus:outline-none text-base p-3 bg-transparent border border-secondary-golden-500 sm:w-full w-full "
                  value={login.Acheviement}
                  onChange={(e) =>
                    setLogin({ ...login, Acheviement: e.target.value })
                  }
                  //   placeholder="Address"
                />
              </div>
              <div className="space-y-3">
                <label htmlFor="role" className="text-lg text-secondary-darkpurple-500">
                  What’s the thing you most hope to learn in your next role?
                </label>
                <textarea
                  rows={5}
                  className="form-textarea rounded focus:outline-none text-base p-3 bg-transparent border border-secondary-golden-500 sm:w-full w-full "
                  value={login.Role}
                  onChange={(e) => setLogin({ ...login, Role: e.target.value })}
                  //   placeholder="Address"
                />
              </div>
              <div>
                <button
                  type="submit"
                  onClick={() => {
                    onFinish();
                  }}
                  className="custom-btn w-full"
                >
                  Submit Your Application
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default JoinOurTeam;